import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import PostListing from "../components/PostListing/PostListing"
import {
  FaPercent,
  FaHome,
  FaBuilding,
  FaDollarSign,
  FaPiggyBank,
} from "react-icons/fa"

export default ({ data }) => {
  return (
    <Layout>
      <main>
        <section
          className="section section-lg bg-secondary overlay-dark text-white"
          style={{ backgroundImage: `url("../blog-hero.jpg")` }}
        >
          <div className="container">
            <div className="row justify-content-center pt-5">
              <div className="col-12">
                <h1 className="display-2"> The latest industry news</h1>
                <p className="lead text-muted mt-4">
                  Discover content written by Australia's{" "}
                  <strong>topbrokers</strong> specifically for you.
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="section section-md pt-5">
          <div className="container">
            <div className="row justify-content-between"></div>
            {/* Post Listings */}
            <div className="section section-md pt-5">
              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-lg-9">
                    <PostListing postEdges={data.allAirtable.edges} />
                  </div>
                  {/* Categories */}
                  <aside className="col-12 col-lg-3 mt-3 mt-lg-0 d-none d-lg-block">
                    <ul className="card list-group list-group-flush shadow-soft border-soft p-3">
                      <li className="list-group-item border-0 py-2 d-flex align-items-center justify-content-between">
                        <a className="text-primary" href="/">
                          <i className="mr-2">
                            <FaDollarSign />
                          </i>
                          Investment
                        </a>
                        <span className="badge badge-soft text-uppercase">
                          0
                        </span>
                      </li>
                      <li className="list-group-item border-0 py-2 d-flex align-items-center justify-content-between">
                        <a className="text-primary" href="/">
                          <i className="mr-2">
                            <FaBuilding />
                          </i>
                          Property
                        </a>
                        <span className="badge badge-soft text-uppercase">
                          0
                        </span>
                      </li>
                      <li className="list-group-item border-0 py-2 d-flex align-items-center justify-content-between">
                        <a className="text-primary" href="/">
                          <i className="mr-2">
                            <FaHome />
                          </i>
                          Home loans
                        </a>
                        <span className="badge badge-soft text-uppercase">
                          0
                        </span>
                      </li>
                      <li className="list-group-item border-0 py-2 d-flex align-items-center justify-content-between">
                        <a className="text-primary" href="/">
                          <i className="mr-2">
                            <FaPiggyBank />
                          </i>
                          Planning
                        </a>
                        <span className="badge badge-soft text-uppercase">
                          0
                        </span>
                      </li>
                      <li className="list-group-item border-0 py-2 d-flex align-items-center justify-content-between">
                        <a className="text-primary" href="/">
                          <i className="mr-2">
                            <FaPercent />
                          </i>
                          Interest rates
                        </a>
                        <span className="badge badge-soft text-uppercase">
                          0
                        </span>
                      </li>
                    </ul>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </Layout>
  )
}

export const postQuery = graphql`
  query BlogPostQuery {
    allAirtable(
      filter: { table: { eq: "Posts" }, data: { Status: { eq: "Published" } } }
      limit: 10
      sort: { fields: data___Date, order: DESC }
    ) {
      edges {
        node {
          recordId
          data {
            Title
            Slug
            Tags
            Date(formatString: "YYYY-MM-DD")
            Status
            PostMarkdown {
              childMarkdownRemark {
                timeToRead
                html
                excerpt
              }
            }
            Author {
              data {
                Name
              }
            }
            Image {
              url
            }
          }
        }
      }
    }
  }
`
