import React from "react"
import { Link } from "gatsby"
import moment from "moment"
import siteConfig from "../../../data/SiteConfig"
import { FaTwitter, FaFacebook, FaLinkedin } from "react-icons/fa"

class PostListing extends React.Component {
  getPostList() {
    const postList = []
    this.props.postEdges.forEach(postEdge => {
      postList.push({
        key: postEdge.node.recordId,
        path: postEdge.node.data.Slug,
        tags: postEdge.node.data.Tags,
        title: postEdge.node.data.Title,
        author: postEdge.node.data.Author
          ? postEdge.node.data.Author[0].data.Name
          : null,
        date: siteConfig.dateFormat
          ? moment(postEdge.node.data.Date).format(siteConfig.dateFormat)
          : postEdge.node.data.Date,
        postMarkdown: postEdge.node.data.PostMarkdown,
        image: postEdge.node.data.Image[0].url,
      })
    })
    return postList
  }
  render() {
    const postList = this.getPostList()

    return (
      <div>
        {/* Your post list here. */
        postList.map(post => (
          <div className="blog-card mb-4" key={post.key}>
            <div className="card shadow-soft border-soft p-0 p-md-4">
              <div className="card-header pb-2">
                <div className="post-meta">
                  <div className="media d-flex justify-content-between">
                    <div>
                      <span className="d-none d-md-inline font-small ml-1 font-weight-light">
                        {post.date} by&nbsp;
                        <a href="/">{post.author}</a>
                      </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <ul className="social-buttons list-style-none d-flex">
                        <li className="mr-3">
                          <a
                            href="/"
                            target="_blank"
                            className="btn btn-lg btn-link btn-twitter text-twitter"
                          >
                            <i
                              className="fab text-lg"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Share on Twitter"
                              data-original-title="Share on Twitter"
                            >
                              <FaTwitter />
                            </i>
                          </a>
                        </li>
                        <li className="mr-3">
                          <a
                            href="/"
                            target="_blank"
                            className="btn btn-lg btn-link btn-facebook text-facebook"
                          >
                            <i
                              className="fab fa-facebook"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Share on Facebook"
                              data-original-title="Share on Facebook"
                            >
                              <FaFacebook />
                            </i>
                          </a>
                        </li>
                        <li className="mr-3">
                          <a
                            href="/"
                            target="_blank"
                            className="btn btn-lg btn-link btn-linkedin text-instagram"
                          >
                            <i
                              className="fab fa-Linkedin"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Post on Linkedin"
                              data-original-title="Post on Linkedin"
                            >
                              <FaLinkedin />
                            </i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body py-3">
                <Link to={post.path}>
                  <img src={post.image} className="card-img-top" alt=""></img>
                </Link>

                <div className="mt-2 mt-lg-5">
                  <a
                    href="/"
                    className="badge badge-sm badge-secondary text-uppercase mr-1"
                  >
                    Finance
                  </a>
                  <a
                    href="/"
                    className="badge badge-sm badge-primary text-uppercase mr-1"
                  >
                    Business
                  </a>

                  <Link to={post.path} activeClassName="btn btn-primary">
                    <h2 className="mt-3 mb-4">{post.title}</h2>
                  </Link>
                </div>

                <p className="card-text mb-0 mb-lg-4">
                  {post.postMarkdown.childMarkdownRemark.excerpt}
                </p>
              </div>
              <div className="card-footer mx-4 px-0 pt-0">
                <div className="d-flex align-items-center justify-content-between">
                  <span className="text-italic">
                    {post.postMarkdown.childMarkdownRemark.timeToRead} min read
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default PostListing

// < div className = "card mb-4" >
//   <img className="card-img-top" src={post.image} alt=""></img>
//   <div className="card-body">
//     <h2 className="card-title">{post.title}</h2>
//     <p className="card-text">
//       {post.postMarkdown.childMarkdownRemark.excerpt}
//     </p>
//     <Link to={post.path} activeClassName="btn btn-primary">
//       Read post &rarr;
//             </Link>
//   </div>
//   <div className="card-footer text-muted">
//     Posted on {post.date} by&nbsp;
//             <a href="/#">{post.author}</a>
//   </div>
//         </div >
